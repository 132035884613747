import React, { useEffect, useState } from "react";
import internetInstall from "../../../images/internet-install.png"; // with import
import internetPriceGuarantee from "../../../images/internet-price-guarantee.png"; // with import
import isRouter from "../../../images/internet-type.png"; // with import
import internetType from "../../../images/broadband_fibre.png"; // with import
import speedUp from "../../../images/up.svg"; // with import
import speedDown from "../../../images/down.svg"; // with import
import learnMoreImg from "../../../images/learn.png"; // with import

import {
  IResponseInternetPlansDTO,
  EnumPropertyId,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";

interface IInternetPlanLargeCollapsedProps {
  plan: IResponseInternetPlansDTO;
  showMoreDetails: boolean;
  onClickShowMoreDetails: () => void;
  onClickOrderNow: () => void;
  isCompare: boolean;
}

const InternetPlanLargeCollapsed: React.FC<
  IInternetPlanLargeCollapsedProps
> = ({
  plan,
  showMoreDetails,
  onClickShowMoreDetails,
  onClickOrderNow,
  isCompare,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals, setIGlobals] = useGlobalState("globals");

  const [seeMoreRecommended, setSeeMoreRecommended] = useState(false);
  const [seeMoreWhyCarrier, setSeeMoreWhyCarrier] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreRecommended = () => {
    setSeeMoreRecommended(!seeMoreRecommended);
  };

  const onClickSeeMoreWhyCarrier = () => {
    setSeeMoreWhyCarrier(!seeMoreWhyCarrier);
  };

  const onClickBwCalculator = () => {
    window.open(iPlanFilterParams.baseUrl + "bw-calculator", "_self");
  };

  const onClickMoreDetails = (pPlan: IResponseInternetPlansDTO) => {
    setIGlobals({
      ...iGlobals,
      planLearMore: pPlan,
    });

    setTimeout(function () {
      window.open(iPlanFilterParams.baseUrl + "learnmore", "_blank");
    }, 100);
  };

  let isLearnMore = false;
  let learnMoreLink = "";
  let learnMore = iGlobals.productProperties.filter(
    (pp) =>
      pp.productId === plan.productId &&
      pp.propertyCode === EnumPropertyId.LEARN
  );
  if (learnMore.length > 0 && plan.displayName.includes("Security Edge")) {
    learnMoreLink = learnMore[0].value;
    isLearnMore = true;
  }

  return (
    <div className="row internet-card">
      <div className="col-md-3 internet-card-first">
        <button
          type="button"
          className="btn internet-order-btn"
          onClick={onClickOrderNow}
        >
          {isCompare ? "ADD TO COMPARE" : "Order"}
        </button>
      </div>
      <div className="col-md-3 internet-card-first">
        <div className="text-head">Price</div>
        <div>${plan.planPrice.toFixed(2)} / mo.</div>
      </div>
      <div className="col-md-3 internet-card-first">
        <div className="text-head">Term</div>
        <div>{plan.planTerm} Months</div>
      </div>
      <div className="col-md-3 no-border internet-card-first ">
        <div className="text-head">Max Speed</div>
        <div>
          <div className="internet-speed-down">
            <div className="down-speed-icon">
              <img src={speedDown} alt="" />
            </div>
            <div className="down-speed-text">
              {plan.planDownloadSpeed >= 1000
                ? (plan.planDownloadSpeed / 1000).toFixed(0)
                : plan.planDownloadSpeed}
              {plan.planDownloadSpeed >= 1000 ? " Gbps" : " Mbps"}
              {/* <span
                  className={`${
                    plan.planDownloadSpeed >= 1000
                      ? "card-icon-gbps"
                      : "card-icon-mbps"
                  } float-end`}
                ></span> */}
            </div>
          </div>
          <div className="internet-speed-up">
            <div className="up-speed-icon">
              <img src={speedUp} alt="" />
            </div>
            <div className="up-speed-text">
              {plan.planUploadSpeed >= 1000
                ? (plan.planUploadSpeed / 1000).toFixed(0)
                : plan.planUploadSpeed}
              {plan.planUploadSpeed >= 1000 ? " Gbps" : " Mbps"}
              {/* <span
                  className={`${
                    plan.planUploadSpeed >= 1000
                      ? "card-icon-gbps"
                      : "card-icon-mbps"
                  } float-end`}
                ></span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 card-float-int">
        {isLearnMore === true && learnMoreLink != "" ? (
          <table className="table internet-card-table-second-five">
            <thead></thead>
            <tbody className="card-more-table">
              <tr>
                <td className="internet-price-guarantee">
                  <img src={internetPriceGuarantee} alt="" />
                </td>
                <td className="internet-install">
                  <img src={internetInstall} alt="" />
                </td>
                <td className="is-router">
                  <img src={isRouter} alt="" />
                </td>
                <td className="internet-type">
                  <img src={internetType} alt="" />
                </td>
                <td className="internet-learn-more">
                  <img src={learnMoreImg} alt="" />
                </td>
              </tr>
              <tr className="align-middle">
                <td>Price Guarantee</td>
                <td>Installation</td>
                <td>Router</td>
                <td>Type</td>
                <td rowSpan={2}>
                  <div>
                    <a
                      className="learn-more-link"
                      href={learnMoreLink}
                      target="_balnk"
                    >
                      Learn More
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.PRICE_GUARANTEE
                      )?.value
                    }
                  </div>
                </td>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.INSTALLATION
                      )?.value
                    }
                  </div>
                </td>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.ROUTER
                      )?.value
                    }
                  </div>
                </td>
                <td>
                  <div>{plan.planTechnologyName}</div>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className="table internet-card-table-second">
            <thead></thead>
            <tbody className="card-more-table">
              <tr>
                <td className="internet-price-guarantee">
                  <img src={internetPriceGuarantee} alt="" />
                </td>
                <td className="internet-install">
                  <img src={internetInstall} alt="" />
                </td>
                <td className="is-router">
                  <img src={isRouter} alt="" />
                </td>
                <td className="internet-type">
                  <img src={internetType} alt="" />
                </td>
              </tr>
              <tr className="align-middle">
                <td>Price Guarantee</td>
                <td>Installation</td>
                <td>Router</td>
                <td>Type</td>
              </tr>
              <tr>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.PRICE_GUARANTEE
                      )?.value
                    }
                  </div>
                </td>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.INSTALLATION
                      )?.value
                    }
                  </div>
                </td>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.ROUTER
                      )?.value
                    }
                  </div>
                </td>
                <td>
                  <div>{plan.planTechnologyName}</div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      {!showMoreDetails && (
        <>
          {/* <hr className="card-hr" />
          <br />
          <div className="col-md-12">
            <table className="table card-table">
              <thead>
                <tr className="align-middle card-font-weight">
                  <th scope="col">Installation</th>
                  <th scope="col">Dedicated / shared internet</th>
                  <th scope="col">Tech support</th>
                  <th scope="col">Technology</th>
                  <th scope="col">Static IP</th>
                </tr>
              </thead>
            </table>
          </div>
          <hr className="card-hr" />
          <br /> */}
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-3" />
              <div className="col-sm-3">
                {/* <div className="card-center-call">
                  <span
                    className="card-view-call metrics-SearchCall"
                    data-name={plan.planName}
                    data-Price={plan.planPrice.toFixed(2)}
                    data-speed={plan.planDownloadSpeed}
                    data-linephone="N/A"
                    data-term={plan.planTerm}
                  >
                    <p>
                      Call to Get Advice <b>1.844.947.7737</b>
                    </p>
                  </span>
                </div> */}
              </div>
              <div className="col-sm-3">
                <span
                  className="card-view-call link-url metrics-SearchViewDetail"
                  onClick={onClickShowMoreDetails}
                  data-name={plan.planName}
                  data-Price={plan.planPrice.toFixed(2)}
                  data-speed={plan.planDownloadSpeed}
                  data-linephone="N/A"
                  data-term={plan.planTerm}
                >
                  View more details
                  <i
                    className="fa fa-chevron-down view-more-icon"
                    aria-hidden="true"
                  />
                </span>
                {/* <button
                  type="button"
                  className={`btn card-button-quote ${
                    isCompare
                      ? "metrics-SearchComparePlan"
                      : "metrics-SearchRequestNow"
                  }`}
                  onClick={onClickOrderNow}
                  data-name={plan.planName}
                  data-Price={plan.planPrice.toFixed(2)}
                  data-speed={plan.planDownloadSpeed}
                  data-linephone="N/A"
                  data-term={plan.planTerm}
                >
                  {isCompare ? "ADD TO COMPARE" : "Order Now"}
                </button> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InternetPlanLargeCollapsed;
